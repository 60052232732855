(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

var obj = {

    /**
     * Extend the first object by appending
     * the second objects fields to it.
     *
     * @param first
     * @param second
     */
    extend: function extend(first, second) {
        Object.keys(second).forEach(function (key) {
            first[key] = second[key];
        });
    }

};

module.exports = obj;

},{}],2:[function(require,module,exports){
'use strict';

var path = {

    /**
     * Get the current url path name after the domain name
     * without hash parameters.
     * Ex.) http://domain.com/landing#extras => 'landing'
     *
     * @returns {string}
     */
    current: function current() {
        return window.location.pathname.substr(1);
    },

    /**
     * Check is a string is in parameter formatting.
     * Parameters are wrapped in `{}`
     *
     * @param str
     * @returns {boolean}
     */
    isParameter: function isParameter(str) {
        return str[0] === '{' && str[str.length - 1] === '}';
    },

    /**
     * Check if the current url matches the given url
     * considering route parameter values.
     *
     * @param {string} path Path to compare against
     * @returns {boolean}
     */
    isMatch: function isMatch(compare) {
        var currentPathParts = path.current().split('/');
        var compareParts = compare.split('/');
        var match = true;

        // urls without the same number of parts cannot match
        if (currentPathParts.length !== compareParts.length) {
            return false;
        }

        // any non parameter url parts must match
        currentPathParts.forEach(function (el, i) {
            if (!path.isParameter(compareParts[i]) && el !== compareParts[i]) {
                match = false;
            }
        });

        return match;
    },

    /**
     * Parse parameter in the form `{name}`.
     *
     * @param {string} parameter String parameter with brackets
     * @returns {string} Name of the parameter
     */
    parameterName: function parameterName(parameter) {
        return parameter.substr(1, parameter.length - 2);
    },

    /**
     * Match a path against the current url and
     * extract the actual parameter values.
     *
     * @param {string} match Url to match against
     * @returns {object} Parameter name => current url value pairs
     */
    parameters: function parameters(match) {
        var currentParts = path.current().split('/');
        var matchParts = match.split('/');
        var options = {};

        matchParts.forEach(function (el, i) {
            if (path.isParameter(el)) {
                options[path.parameterName(el)] = currentParts[i];
            }
        });

        return options;
    }

};

module.exports = path;

},{}],3:[function(require,module,exports){
'use strict';

var objects = require('./objects');
var path = require('./path');

var router = {

    /**
     * Configure the router
     *
     * @param configuration
     */
    config: function config(configuration) {
        objects.extend(this.configuration, configuration);
    },

    before: function before(app) {
        if (app.hasOwnProperty('before')) {
            app.before();
        }
    },

    after: function after(app) {
        if (app.hasOwnProperty('after')) {
            app.after();
        }
    },

    /**
     * @param {object} options
     */
    route: function route(options) {
        var self = this;
        var routeConfig = options.hasOwnProperty('routes') ? options.routes : options || {};

        if (options.hasOwnProperty('config')) {
            this.config(options.config);
        }
        this.before(options);

        generateRoutes.call(this, routeConfig, function (routes) {
            Object.keys(routes).forEach(function (route) {
                if (path.isMatch(route)) {
                    routes[route](path.parameters(route));
                    self.after(options);
                }
            });
        });
    }

};

/**
 * Generate routes from the initial route configuration
 * object provided by the user.
 *
 * @param routeConfig
 * @param cb
 */
function generateRoutes(routeConfig, cb) {
    var self = this;
    var routes = {};
    var toProcess = Object.keys(routeConfig).length;
    var processed = 0;

    Object.keys(routeConfig).forEach(function (key) {
        var value = routeConfig[key];

        if (typeof value === 'function') {

            // the value is already a function
            routes[key] = value;
            process();
        }
    });

    // on sucess process of route name
    function process() {
        if (++processed === toProcess) {
            cb(routes);
        }
    }
}

module.exports = router;

},{"./objects":1,"./path":2}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var app = {

    routes: {

        '': fab,
        'clinics': fab,
        'animals': fab,

        'animals/create': function animalsCreate() {
            (function ($) {
                $(document).ready(function () {

                    app.checkInputs();

                    $("#vet_clinic_pickup").click(app.enableClinicPickup);
                    $("#home_delivery_pickup").click(app.enableHomePickup);
                    $("#acs_pickup").click(app.enableAcsPickup);

                    $("#vet_clinic_return").click(app.enableClinicReturn);
                    $("#home_delivery_return").click(app.enableHomeReturn);
                    $("#acs_return").click(app.enableAcsReturn);

                    $('#funeral_rush').click(function () {
                        if ($(this).is(':checked')) {
                            $('#funeral_rush_date').prop('disabled', false);
                        } else {
                            $('#funeral_rush_date').prop('disabled', true);
                        }
                    });

                    if ($('#funeral_rush').is(':checked')) {
                        $('#funeral_rush_date').prop('disabled', false);
                    } else {
                        $('#funeral_rush_date').prop('disabled', true);
                    }

                    $('#animal_weight').on('load input', function () {
                        if ($(this).val() > 40) {
                            $('#urns_brass').prop('disabled', true);
                        } else {
                            $('#urns_brass').prop('disabled', false);
                        }

                        if ($(this).val() > 79) {
                            $('#urns_black_ceramic').prop('disabled', true);
                            $('#urns_white_ceramic').prop('disabled', true);
                        } else {
                            $('#urns_black_ceramic').prop('disabled', false);
                            $('#urns_white_ceramic').prop('disabled', false);
                        }
                    });

                    app.toggleWriting();

                    $('input[type=checkbox]').click(function () {
                        app.toggleWriting();
                    });

                    // Target the first input (checkbox) and toggle the next input on and off
                    // Used in the Merchandise section

                    $(".merchandise-wrapper input[type='checkbox']").each(function () {
                        $(this).parent().next().children('input[type="text"]').prop("disabled", !this.checked);
                    }).click(function () {
                        $(this).parent().next().children('input[type="text"]').prop("disabled", !this.checked);
                        if ($(this).is(':checked')) {
                            $(this).parent().next().children('input[type="text"]').val('1');
                        } else {
                            $(this).parent().next().children('input[type="text"]').val('');
                        }
                    });

                    //$('input[type="date"]').each(function() {
                    //    var el = this, type = $(el).attr('type');
                    //    if ($(el).val() == '') $(el).attr('type', 'text');
                    //    $(el).focus(function() {
                    //        $(el).attr('type', type);
                    //        el.click();
                    //    });
                    //    $(el).blur(function() {
                    //        if ($(el).val() == '') $(el).attr('type', 'text');
                    //    });
                    //});

                    $('#pickup_fields').hide();

                    $('#home_pickup').click(function () {
                        if ($(this).is(':checked')) {
                            $('#pickup_fields').show();
                        }
                    });

                    $('#vet_clinic_pickup').click(function () {
                        if ($(this).is(':checked')) {
                            $('#pickup_fields').hide();
                        }
                    });

                    $('#acs_pickup').click(function () {
                        if ($(this).is(':checked')) {
                            $('#pickup_fields').hide();
                        }
                    });

                    $('#delivery_fields').hide();

                    $('#home_return').click(function () {
                        if ($(this).is(':checked')) {
                            $('#delivery_fields').show();
                        }
                    });

                    $('#vet_clinic_return').click(function () {
                        if ($(this).is(':checked')) {
                            $('#delivery_fields').hide();
                        }
                    });

                    $('#acs_return').click(function () {
                        if ($(this).is(':checked')) {
                            $('#delivery_fields').hide();
                        }
                    });
                });
            })(jQuery);
        },

        'merchandise/orders/create': function merchandiseOrdersCreate() {
            (function ($) {
                $(document).ready(function () {

                    app.checkInputs();

                    $("#vet_clinic_pickup").click(app.enableClinicPickup);
                    $("#home_delivery_pickup").click(app.enableHomePickup);
                    $("#acs_pickup").click(app.enableAcsPickup);

                    $("#vet_clinic_return").click(app.enableClinicReturn);
                    $("#home_delivery_return").click(app.enableHomeReturn);
                    $("#acs_return").click(app.enableAcsReturn);

                    $('#funeral_rush').click(function () {
                        if ($(this).is(':checked')) {
                            $('#funeral_rush_date').prop('disabled', false);
                        } else {
                            $('#funeral_rush_date').prop('disabled', true);
                        }
                    });

                    if ($('#funeral_rush').is(':checked')) {
                        $('#funeral_rush_date').prop('disabled', false);
                    } else {
                        $('#funeral_rush_date').prop('disabled', true);
                    }

                    $('.writing-wrapper').hide();
                    $('.writing-heading').hide();

                    app.toggleWriting();

                    $('input[type=checkbox]').click(function () {
                        app.toggleWriting();
                    });
                    // Target the first input (checkbox) and toggle the next input on and off
                    // Used in the Merchandise section

                    $(".merchandise-wrapper input[type='checkbox']").each(function () {
                        $(this).parent().next().children('input[type="text"]').prop("disabled", !this.checked);
                    }).click(function () {
                        $(this).parent().next().children('input[type="text"]').prop("disabled", !this.checked);
                        if ($(this).is(':checked')) {
                            $(this).parent().next().children('input[type="text"]').val('1');
                        } else {
                            $(this).parent().next().children('input[type="text"]').val('');
                        }
                    });

                    //$('input[type="date"]').each(function() {
                    //    var el = this, type = $(el).attr('type');
                    //    if ($(el).val() == '') $(el).attr('type', 'text');
                    //    $(el).focus(function() {
                    //        $(el).attr('type', type);
                    //        el.click();
                    //    });
                    //    $(el).blur(function() {
                    //        if ($(el).val() == '') $(el).attr('type', 'text');
                    //    });
                    //});

                    $('#pickup_fields').hide();

                    $('#home_pickup').click(function () {
                        if ($(this).is(':checked')) {
                            $('#pickup_fields').show();
                        }
                    });

                    $('#vet_clinic_pickup').click(function () {
                        if ($(this).is(':checked')) {
                            $('#pickup_fields').hide();
                        }
                    });

                    $('#acs_pickup').click(function () {
                        if ($(this).is(':checked')) {
                            $('#pickup_fields').hide();
                        }
                    });

                    $('#delivery_fields').hide();

                    $('#home_return').click(function () {
                        if ($(this).is(':checked')) {
                            $('#delivery_fields').show();
                        }
                    });

                    $('#vet_clinic_return').click(function () {
                        if ($(this).is(':checked')) {
                            $('#delivery_fields').hide();
                        }
                    });

                    $('#acs_return').click(function () {
                        if ($(this).is(':checked')) {
                            $('#delivery_fields').hide();
                        }
                    });
                });
            })(jQuery);
        },

        'merchandise/orders/create/{id}': function merchandiseOrdersCreateId() {
            (function ($) {
                $(document).ready(function () {

                    app.checkInputs();

                    $("#vet_clinic_pickup").click(app.enableClinicPickup);
                    $("#home_delivery_pickup").click(app.enableHomePickup);
                    $("#acs_pickup").click(app.enableAcsPickup);

                    $("#vet_clinic_return").click(app.enableClinicReturn);
                    $("#home_delivery_return").click(app.enableHomeReturn);
                    $("#acs_return").click(app.enableAcsReturn);

                    $('#funeral_rush').click(function () {
                        if ($(this).is(':checked')) {
                            $('#funeral_rush_date').prop('disabled', false);
                        } else {
                            $('#funeral_rush_date').prop('disabled', true);
                        }
                    });

                    if ($('#funeral_rush').is(':checked')) {
                        $('#funeral_rush_date').prop('disabled', false);
                    } else {
                        $('#funeral_rush_date').prop('disabled', true);
                    }

                    $('.writing-wrapper').hide();
                    $('.writing-heading').hide();

                    app.toggleWriting();

                    $('input[type=checkbox]').click(function () {
                        app.toggleWriting();
                    });
                    // Target the first input (checkbox) and toggle the next input on and off
                    // Used in the Merchandise section

                    $(".merchandise-wrapper input[type='checkbox']").each(function () {
                        $(this).parent().next().children('input[type="text"]').prop("disabled", !this.checked);
                    }).click(function () {
                        $(this).parent().next().children('input[type="text"]').prop("disabled", !this.checked);
                        if ($(this).is(':checked')) {
                            $(this).parent().next().children('input[type="text"]').val('1');
                        } else {
                            $(this).parent().next().children('input[type="text"]').val('');
                        }
                    });

                    //$('input[type="date"]').each(function() {
                    //    var el = this, type = $(el).attr('type');
                    //    if ($(el).val() == '') $(el).attr('type', 'text');
                    //    $(el).focus(function() {
                    //        $(el).attr('type', type);
                    //        el.click();
                    //    });
                    //    $(el).blur(function() {
                    //        if ($(el).val() == '') $(el).attr('type', 'text');
                    //    });
                    //});

                    $('#pickup_fields').hide();

                    $('#home_pickup').click(function () {
                        if ($(this).is(':checked')) {
                            $('#pickup_fields').show();
                        }
                    });

                    $('#vet_clinic_pickup').click(function () {
                        if ($(this).is(':checked')) {
                            $('#pickup_fields').hide();
                        }
                    });

                    $('#acs_pickup').click(function () {
                        if ($(this).is(':checked')) {
                            $('#pickup_fields').hide();
                        }
                    });

                    $('#delivery_fields').hide();

                    $('#home_return').click(function () {
                        if ($(this).is(':checked')) {
                            $('#delivery_fields').show();
                        }
                    });

                    $('#vet_clinic_return').click(function () {
                        if ($(this).is(':checked')) {
                            $('#delivery_fields').hide();
                        }
                    });

                    $('#acs_return').click(function () {
                        if ($(this).is(':checked')) {
                            $('#delivery_fields').hide();
                        }
                    });
                });
            })(jQuery);
        },

        'animals/{id}/edit': function animalsIdEdit(params) {
            (function ($) {
                $(document).ready(function () {
                    $('#delivery_fields').hide();
                    app.checkInputs();

                    $("#vet_clinic_pickup").click(app.enableClinicPickup);
                    $("#home_delivery_pickup").click(app.enableHomePickup);
                    $("#acs_pickup").click(app.enableAcsPickup);

                    $("#vet_clinic_return").click(app.enableClinicReturn);
                    $("#home_delivery_return").click(app.enableHomeReturn);
                    $("#acs_return").click(app.enableAcsReturn);

                    $('#funeral_rush').click(function () {
                        if ($(this).is(':checked')) {
                            $('#funeral_rush_date').prop('disabled', false);
                        } else {
                            $('#funeral_rush_date').prop('disabled', true);
                        }
                    });

                    if ($('#funeral_rush').is(':checked')) {
                        $('#funeral_rush_date').prop('disabled', false);
                    } else {
                        $('#funeral_rush_date').prop('disabled', true);
                    }

                    $('#animal_weight').on('load input', function () {
                        if ($(this).val() > 40) {
                            $('#urns_brass').prop('disabled', true);
                        } else {
                            $('#urns_brass').prop('disabled', false);
                        }

                        if ($(this).val() > 79) {
                            $('#urns_black_ceramic').prop('disabled', true);
                            $('#urns_white_ceramic').prop('disabled', true);
                        } else {
                            $('#urns_black_ceramic').prop('disabled', false);
                            $('#urns_white_ceramic').prop('disabled', false);
                        }
                    });

                    app.toggleWriting();

                    $('input[type=checkbox]').click(function () {
                        app.toggleWriting();
                    });

                    // Target the first input (checkbox) and toggle the next input on and off
                    // Used in the Merchandise section

                    $(".merchandise-wrapper input[type='checkbox']").each(function () {
                        $(this).parent().next().children('input[type="text"]').prop("disabled", !this.checked);
                    }).click(function () {
                        $(this).parent().next().children('input[type="text"]').prop("disabled", !this.checked);
                        if ($(this).is(':checked')) {
                            $(this).parent().next().children('input[type="text"]').val('1');
                        } else {
                            $(this).parent().next().children('input[type="text"]').val('0');
                        }
                    });

                    //$('input[type="date"]').each(function() {
                    //    var el = this, type = $(el).attr('type');
                    //    if ($(el).val() == '') $(el).attr('type', 'text');
                    //    $(el).focus(function() {
                    //        $(el).attr('type', type);
                    //        el.click();
                    //    });
                    //    $(el).blur(function() {
                    //        if ($(el).val() == '') $(el).attr('type', 'text');
                    //    });
                    //});

                    $('#pickup_fields').hide();

                    $('#home_pickup').click(function (e) {
                        if ($(e.target).is(':checked')) {
                            $('#pickup_fields').show();
                        }
                    });

                    $('#vet_clinic_pickup').click(function (e) {
                        if ($(e.target).is(':checked')) {
                            $('#pickup_fields').hide();
                        }
                    });

                    $('#acs_pickup').click(function (e) {
                        if ($(e.target).is(':checked')) {
                            $('#pickup_fields').hide();
                        }
                    });

                    $('#home_return').click(function (e) {
                        if ($(e.target).is(':checked')) {
                            $('#delivery_fields').show();
                        }
                    });

                    $('#vet_clinic_return').click(function (e) {
                        if ($(e.target).is(':checked')) {
                            $('#delivery_fields').hide();
                        }
                    });

                    $('#acs_return').click(function (e) {
                        if ($(e.target).is(':checked')) {
                            $('#delivery_fields').hide();
                        }
                    });
                });
            })(jQuery);
        },
        'driver/route/stop/invoice/{id}': function driverRouteStopInvoiceId(params) {
            var wrapper = document.getElementById("signature-pad"),
                canvas = wrapper.querySelector("canvas"),
                signaturePad;

            // Adjust canvas coordinate space taking into account pixel ratio,
            // to make it look crisp on mobile devices.
            // This also causes canvas to be cleared.
            function resizeCanvas() {
                // When zoomed out to less than 100%, for some very strange reason,
                // some browsers report devicePixelRatio as less than 1
                // and only part of the canvas is cleared then.
                var ratio = Math.max(window.devicePixelRatio || 1, 1);
                canvas.width = canvas.offsetWidth * ratio;
                canvas.height = canvas.offsetHeight * ratio;
                canvas.getContext("2d").scale(ratio, ratio);
            }

            window.onresize = resizeCanvas;
            resizeCanvas();

            signaturePad = new SignaturePad(canvas);

            signaturePad = new SignaturePad(document.getElementById("sign"), {
                onEnd: function onEnd() {
                    // assign to hidden input instead
                    $('#signature').val(signaturePad.toDataURL());
                }
            });

            $('#clear-signature').click(function () {
                signaturePad.clear();
            });

            var data = canvas.dataset.signatureUri; // or you can use jQuery for that
            if (data) {
                signaturePad.fromDataURL(data);
            }
            $(".merchandise-wrapper input[type='checkbox']").each(function () {
                $(this).parent().next().children('input[type="text"]').prop("disabled", !this.checked);
                $(this).parent().next().children('textarea').prop("disabled", !this.checked);
            }).click(function () {
                $(this).parent().next().children('input[type="text"]').prop("disabled", !this.checked);
                $(this).parent().next().children('textarea').prop("disabled", !this.checked);
                if ($(this).is(':checked')) {
                    $(this).parent().next().children('input[type="text"]').val('1');
                } else {
                    $(this).parent().next().children('input[type="text"]').val('');
                    $(this).parent().next().children('textarea').val('');
                }
            });
        },
        'stops/create': function stopsCreate() {
            (function ($) {
                $(".merchandise-wrapper input[type='checkbox']").each(function () {
                    $(this).parent().next().children('input[type="text"]').prop("disabled", !this.checked);
                    $(this).parent().next().children('textarea').prop("disabled", !this.checked);
                }).click(function () {
                    $(this).parent().next().children('input[type="text"]').prop("disabled", !this.checked);
                    $(this).parent().next().children('textarea').prop("disabled", !this.checked);
                    if ($(this).is(':checked')) {
                        $(this).parent().next().children('input[type="text"]').val('1');
                    } else {
                        $(this).parent().next().children('input[type="text"]').val('');
                        $(this).parent().next().children('textarea').val('');
                    }
                });
            })(jQuery);
        }
    },

    toggleWriting: function toggleWriting() {
        $('.writing-wrapper').show();
        $('.writing-heading').show();
        $('#writing_line_two').show();
        $('#writing_line_three').show();
        $('.tag-header').show();
        $('.tag-wrapper').show();

        if (!$('.merchandise-wrapper .form-group:not(.urns) input[type="checkbox"]').is(':checked')) {
            if (($('input[id=urns_pewter_cat]').is(':checked') || $('input[id=urns_black_ceramic_cat]').is(':checked') || $('input[id=urns_white_ceramic_cat]').is(':checked') || $('input[id=urns_pewter_odyssey]').is(':checked') || $('input[id=urns_copper_odyssey]').is(':checked') || $('input[id=urns_brass]').is(':checked') || $('input[id=urns_white_ceramic]').is(':checked') || $('input[id=urns_black_ceramic]').is(':checked') || $('input[id=urns_shotgun_shell_black]').is(':checked') || $('input[id=urns_shotgun_shell_red]').is(':checked') || $('input[id=urns_shotgun_shell_dark_green]').is(':checked')) && $('input[id=urns_tin]').is(':not(:checked)') && $('input[id=urns_memorial_rock]').is(':not(:checked)')) {
                $('#writing_line_two').hide();
                $('#writing_line_three').hide();
            }
        }
        if (!$('#boxes_redwood').is(':checked') && !$('#boxes_acrylic').is(':checked')) {
            $('.tag-header').hide();
            $('.tag-wrapper').hide();
        }

        if ($('input[id=misc_clay_paw_print]').is(':checked') && !$('.merchandise-wrapper .urns input[type="checkbox"]').is(':checked')) {
            $('#writing_line_two').hide();
            $('#writing_line_three').hide();
        }

        if ($('input[id=plain_box]').is(':checked') && !$('.merchandise-wrapper input[type="checkbox"]').is(':checked') || $('.merchandise-wrapper input[type="checkbox"]:checked').length == 1 && $('input[id=urns_mossy_oak_camo_odyssey]').is(':checked')) {
            $('.writing-wrapper').hide();
            $('.writing-heading').hide();
        }
    },

    checkInputs: function checkInputs() {
        if ($("#vet_clinic_pickup").is(':checked')) {
            app.enableClinicPickup();
        }
        if ($("#home_delivery_pickup").is(':checked')) {
            app.enableHomePickup();
        }
        if ($("#acs_pickup").is(':checked')) {
            app.enableAcsPickup();
        }

        if ($("#vet_clinic_return").is(':checked')) {
            app.enableClinicReturn();
        }
        if ($("#home_return").is(':checked')) {
            app.enableHomeReturn();
            $('#delivery_fields').show();
        }
        if ($("#acs_return").is(':checked')) {
            app.enableAcsReturn();
        }

        $(document).on('change', '#vet_clinic_pickup_select', function () {
            var val = $(this).val();

            $('#vet_clinic_return_select').selectpicker('val', val);
        });
    },
    enableClinicPickup: function enableClinicPickup() {
        $("#vet_clinic_pickup_input").prop("disabled", false);
        $("#home_delivery_pickup_input").prop("disabled", true);
    },
    enableHomePickup: function enableHomePickup() {
        $("#home_delivery_pickup_input").prop("disabled", false);
        $("#vet_clinic_pickup_input").prop("disabled", true);
    },
    enableAcsPickup: function enableAcsPickup() {
        $("#home_delivery_pickup_input").prop("disabled", true);
        $("#vet_clinic_pickup_input").prop("disabled", true);
    },
    enableClinicReturn: function enableClinicReturn() {
        $("#vet_clinic_return_input").prop("disabled", false);
        $("#home_delivery_return_input").prop("disabled", true);
    },
    enableHomeReturn: function enableHomeReturn() {
        $("#home_delivery_return_input").prop("disabled", false);
        $("#vet_clinic_return_input").prop("disabled", true);
    },
    enableAcsReturn: function enableAcsReturn() {
        $("#home_delivery_return_input").prop("disabled", true);
        $("#vet_clinic_return_input").prop("disabled", true);
    }
};
function fab() {
    (function ($) {
        var $this = undefined;

        $(document).ready(function () {

            // jQuery reverse
            $.fn.reverse = [].reverse;

            // Hover behaviour: make sure this doesn't work on .click-to-toggle FABs!
            $(document).on('mouseenter.fixedActionBtn', '.fixed-action-btn:not(.click-to-toggle)', function (e) {
                var $this = $(this);
                openFABMenu($this);
            });
            $(document).on('mouseleave.fixedActionBtn', '.fixed-action-btn:not(.click-to-toggle)', function (e) {
                var $this = $(this);
                closeFABMenu($this);
            });

            // Toggle-on-click behaviour.
            $(document).on('click.fixedActionBtn', '.fixed-action-btn.click-to-toggle > a', function (e) {
                var $this = $(this);
                var $menu = $this.parent();
                if ($menu.hasClass('active')) {
                    closeFABMenu($menu);
                } else {
                    openFABMenu($menu);
                }
            });
        });

        $.fn.extend({
            openFAB: function openFAB() {
                openFABMenu($(this));
            },
            closeFAB: function closeFAB() {
                closeFABMenu($(this));
            }
        });

        var openFABMenu = function openFABMenu(btn) {
            $this = btn;
            if ($this.hasClass('active') === false) {

                // Get direction option
                var horizontal = $this.hasClass('horizontal');
                var offsetY, offsetX;

                if (horizontal === true) {
                    offsetX = 40;
                } else {
                    offsetY = 40;
                }

                $this.addClass('active');
                $this.find('ul .btn-floating').velocity({ scaleY: ".4", scaleX: ".4", translateY: offsetY + 'px', translateX: offsetX + 'px' }, { duration: 0 });

                var time = 0;
                $this.find('ul .btn-floating').reverse().each(function () {
                    $(this).velocity({ opacity: "1", scaleX: "1", scaleY: "1", translateY: "0", translateX: '0' }, { duration: 80, delay: time });
                    time += 40;
                });
            }
        };

        var closeFABMenu = function closeFABMenu(btn) {
            $this = btn;
            // Get direction option
            var horizontal = $this.hasClass('horizontal');
            var offsetY, offsetX;

            if (horizontal === true) {
                offsetX = 40;
            } else {
                offsetY = 40;
            }

            $this.removeClass('active');
            var time = 0;
            $this.find('ul .btn-floating').velocity("stop", true);
            $this.find('ul .btn-floating').velocity({ opacity: "0", scaleX: ".4", scaleY: ".4", translateY: offsetY + 'px', translateX: offsetX + 'px' }, { duration: 80 });
        };
    })(jQuery);
}
exports.default = app;

},{}],5:[function(require,module,exports){
'use strict';

var _app = require('./app/controllers/app.js');

var _app2 = _interopRequireDefault(_app);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var router = require('../../../bower_components/motors-router/src/browserify/router'); //import './scripts.js';


$(document).ready(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    //Global Check all

    $('#select_all').click(function () {
        $('.panel input[type="checkbox"]').prop('checked', this.checked);
    });

    //Get a count of the checkboxes and disable and buttons with the class .bulk-btn if = 0

    var checkboxes = $('input[type="checkbox"]');

    $('.bulk-btn').prop({
        disabled: true
    });

    checkboxes.change(function () {
        var countCheckedCheckboxes = checkboxes.filter(':checked').length;

        if (countCheckedCheckboxes > 0) {
            $('.bulk-btn').prop({
                disabled: false
            });
        } else {
            $('.bulk-btn').prop({
                disabled: true
            });
        }
    });

    router.route(_app2.default);

    $('.set-dismiss-cookie').click(function () {
        setDismissCookie($(this).data('cookiename'));
    });

    function setDismissCookie(cookieName) {
        $.ajax({
            url: '/ajax/set/cookie',
            method: 'POST',
            data: { name: cookieName }
        });
    }
});

},{"../../../bower_components/motors-router/src/browserify/router":3,"./app/controllers/app.js":4}]},{},[5]);
